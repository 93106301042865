.main {
    top: 0;
    margin-top: 0;
    min-height: 100vh;
    width: 100vw;
    max-width: 100vw;
    display: inline;

}

.container {
    min-height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

}
.collumn{
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
}

.titleBanner {
    width: 100%;
    height: 100px;
    margin-top: 120px;
    color: white;
    background-color: #2B3618;
    text-align: center;
    font-family: corbenReg;
    font-size: 48px;
    font-weight: 900
}

.row {
    display: flex;
    height: 50px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 12px;
}

.txt {
    font-weight: 300;
    font-size: 18px;
}

.boldTxt {
    font-weight: 900;
    font-size: 18px;
    margin-left: 12px;
    margin-right: 12px;
}

.smallTxt {
    font-weight: 300;
    margin-left: 9px;
    margin-right: 9px;
}

.dTxt {
    font-family: corbenReg;
    font-size: 22px;
    line-height: 24px;
    margin-top: 9px;
}

.orderLogo {
    cursor: pointer;
    margin-top: -66px;
}

.clearBtn {
    font-family: corbenBold;
    font-weight: 500;
    margin-top: 0px;
    margin-bottom: 0px;
    background-color: rgb(243, 243, 243);
    color: black;
    border-radius: 9px;
    backdrop-filter: blur(8px);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 33px;
    padding: 9px;
    cursor: pointer;


}

.clearBtn:hover {
    transition-delay: 1s;
    transition: ease-out;
    transition-duration: .6s;
    background-color: rgba(224, 224, 224, 0.959);
    border: none;
    box-shadow: none;

}