.main {
    width: 100vw;
    max-width: 100vw;
    height: 90px;
    background-color: rgba(255, 255, 255, 0.747);
    backdrop-filter: blur(12px);
    position: fixed;
    display: flex;
    z-index: 2;
    margin-top: 30px;




}

.row {
    width: 100%;
    height: 100%;
    max-width: 100vw;
    color: #FFFFFF;
    display: flex;
    justify-content: space-between;
}

.logo {

    padding-left: 18px;
    height: 100%;
    
    cursor: pointer;
    
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-self: left;





}

.right {
    padding-right: 18px;
    display: flex;
    justify-content: right;
    align-items: center;

}

.menu {
    align-items: center;
    height: max-content;

    font-size: 16px;
    font-weight: 700;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 6px;
    padding-bottom: 6px;



    background: #008600;
    backdrop-filter: blur(8px);

    transition: ease-out;
    transition-duration: .3s;


}

.rightSide {
    align-self: center;
    justify-self: center;


}

.pages {
    flex-basis: 1;
    display: flex;
    height: 32px;


    width: 560px;
    max-width: 60vw;
    min-width: fit-content;
    justify-content: space-between;
    justify-self: center;




}

.page {
    margin-top: -1px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;

    height: 24px;
    align-content: center;
    align-items: center;

    padding-left: 9px;
    padding-right: 9px;

}

.page:hover {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 3px;
}



.search {
    padding-right: min(16px, 1.5%);
    margin-left: auto;


}

.bg {
    color: rgba(0, 0, 0, 0.06);
    backdrop-filter: blur(4.5px);
    position: absolute;
    width: 100vw;
    max-width: 100vw;
    height: calc(var(--vh, 1vh) *100);

    z-index: 1;
}

.dropDown {
    background-color: rgba(255, 255, 255, 0.84);
    position: absolute;
    margin-top: 0;
    right: 0;
    width: 80vw;
    max-width: 380px;
    z-index: 2;
    height: calc(var(--vh, 1vh) *100);
    display: flex;
    flex-direction: column;

}

.iconCont {
    width: 100%;
    display: flex;


}

.close {
    color: black;
    height: 24px;
    margin-left: auto;
    margin-right: 16px;
    margin-top: 16px;
    cursor: pointer;
}

.page2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    color: black;
    width: 80%;
    align-self: center;
    justify-self: center;
    padding-left: 12px;
    margin-top: 12px;
    line-height: 32px;
    height: 32px;

}

.page2:hover {
    background-color: #F0F0F0;

    border-radius: 6px;
}

.logo2 {
    margin-top: auto;
    width: 160px;
    justify-self: center;
    align-self: center;
    margin-bottom: 30px;
}