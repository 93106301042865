.main{
    min-height: fit-content;
    height: 100vh;
    width: 100vw;
    max-width: 100vw;
}
.main::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
}