.main {
    width: 100vw;
    max-width: 100vw;
    height: 90px;
    background-color: rgba(255, 255, 255, 0.747);
    backdrop-filter: blur(12px);
   
    position: fixed;
    display: flex;
    z-index: 1;
    margin-top: 30px;
}

.row {
    width: 100%;
    align-self: center;
    height: 90px;
    max-width: 100vw;
    color: #FFFFFF;
    display: flex;
    justify-content: space-between;
    justify-items: space-between;
   
}

.logo {
    margin-top: 2px;
    margin-left: 20px;
    padding: 9px;
    height: 60px;
    cursor: pointer;
    align-self: center;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-self: left;
    
}

.falseDiv {
    padding: 9px;
    margin-right: 20px;
    width: 134px;
}

.rightSide {
    align-self: center;
    justify-self: center;
}

.pages {
    margin-right: 20px;
    display: flex;
    height: 90px;
    width: 400px;
    max-width: 60vw;
    justify-content: space-between;
    right: 0;
    align-content: center;
    align-items: center;
}

.page {
    margin-top: 0px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    color: black;
    height: 25px;
    align-content: center;
    align-items: center;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 15px;
    padding-right: 15px;

    transition-delay: 1s;
    transition: ease-out;
    transition-duration: .6s;
    display: flex;
    font-family: corbenReg;
    font-weight: 600;


}

.menuDown {
    height: 100%;
    align-self: center;
    justify-self: center;
    margin-left: 3px;
}

.page:hover {
    border-radius: 3px;

    background: rgba(0, 0, 0, 0.06);
    backdrop-filter: blur(8px);

    transition: ease-out;
    transition-duration: .3s;

}

.dropBtnContainer {
    height: 60px;
}

.dropDownContainer {
    background-color: rgba(255, 255, 255, 0.5);
    margin-top: 4px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: black;
    border-radius: 0px 0px 6px 6px;


}

.dropMenuBtn {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    height: 33px;
}

.dropMenuBtn:hover {
    border-radius: 3px;

    background: rgba(0, 0, 0, 0.06);
    backdrop-filter: blur(8px);

    transition: ease-out;
    transition-duration: .3s;

}



.search {
    padding-right: min(16px, 1.5%);
    margin-left: auto;


}