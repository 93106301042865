.main {
    top: 0;
    margin-top: 0;
    width: 100%;
    max-width: 100vw;
    overflow-x: hidden;

    height: 100vh;
    min-height: max-content;
    overflow-y: scroll;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    position: relative;
    display: inline;
    /* Firefox */
}

.main::-webkit-scrollbar {
    overflow: none;
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.tOne {
    height: 100vh;
    max-height: 100vh;
    max-width: 100vw;
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
    overflow: hidden;

    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
}

.tOne::-webkit-scrollbar {
    overflow: none;
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.tTwo {
    min-height: 100vh;
    height: fit-content;
    max-width: 100vw;
    scroll-snap-align: center;
}

.container {
    display: flex;
    flex-direction: column;

    justify-content: center;
    justify-items: center;
    align-items: center;

    overflow: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.container::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.logoContainer {
    display: flex;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;

    margin-top: 120px;
}

.centerLogo {
    width: auto;
    margin-top: 00px;
    width: 700px;
    max-width: 100%;
}

.slogan {
    width: 90%;
    text-align: center;
    font-size: 24px;
    font-family: corbenBold;
    font-weight: 500;
    text-shadow: 0px 0px 3px black;
    margin-top: 33px;
    color: white;
}


.clearBtn {
    font-family: corbenBold;
    font-weight: 500;
    margin-top: 33px;
    background-color: rgba(255, 255, 255, 0.7);
    color: black;
    border-radius: 9px;
    backdrop-filter: blur(8px);


    display: flex;
    align-items: center;
    height: 33px;
    padding: 9px;
    cursor: pointer;

}

.clearBtn:hover {
    transition-delay: 1s;
    transition: ease-out;
    transition-duration: .6s;
    background-color: rgba(224, 224, 224, 0.959);
    border: none;
    box-shadow: none;

}

.clearBtn2 {
    font-family: corbenBold;
    font-weight: 500;
    margin-top: 33px;
    background-color: rgba(255, 255, 255, 0.842);
    color: black;
    border-radius: 12px;
    border: 1px black;
    border-style: solid;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.644);
    display: flex;
    align-items: center;
    height: 33px;
    padding: 9px;
    cursor: pointer;

}

.clearBtn2:hover {
    transition-delay: 1s;
    transition: ease-out;
    transition-duration: .6s;
    background-color: rgba(224, 224, 224);
    border: none;
    box-shadow: none;

}

.whiteBtn {
    font-family: corbenReg;
    font-weight: 600;
    margin-top: 33px;
    color: black;
    background-color: rgba(255, 255, 255, 0.918);
    color: black;
    border-radius: 9px;
    backdrop-filter: blur(8px);
    display: flex;
    align-items: center;
    height: 33px;
    padding-left: 9px;
    padding-right: 9px;
    cursor: pointer;
}

.whiteBtn:hover {
    background-color: rgba(224, 224, 224, 0.659);
    border: none;
    box-shadow: none;
}

.specialCont {
    background-color: #008600;

    display: flex;
    flex-direction: column;

    align-content: center;
    justify-content: center;
    justify-items: center;
    align-items: center;
    border-radius: 9px;
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 6px;
    padding-top: 3px;
    color: white;
    font-family: corbenReg;
    line-height: 22px;



}

.dailySpecial {
    font-size: 16px;
    font-weight: 900;
    font-weight: bolder;
}

.arrow {
    transition: ease-in;
    transition-duration: 70ms;
    color: rgba(255, 255, 255, 0.7);
    text-shadow: 0px 0px 3px black;
    cursor: pointer;
}

.arrowD {
    transition: ease-in;
    transition-duration: 70ms;
    color: rgba(255, 255, 255, 0.7);
    margin-top: auto;
    margin-bottom: 16px;
    text-shadow: 0px 0px 3px black;

}



/*
.container{
   display: flex;
   flex-direction: column;
   height: 100%;
   justify-content: center;
   justify-items: center;
   align-items: center;

}
*/

.multContainer {
    min-height: calc(100vh - 50px);
    height: fit-content;
    margin-top: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 16px;
}

.multContainerM {

    margin-top: auto;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 16px;
    overflow-y: hidden;
    overflow-x: hidden;
    scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.multContainerM::-webkit-scrollbar {
    overflow: none;
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
}


.boardRow {
    display: flex;
    min-height: 600px;
    max-height: 900px;

    flex-wrap: wrap;
    min-height: min-content;
    position: relative;
    margin-bottom: 16px;
}

.boardRowM {
    max-width: 100vw;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 16px;

}

.menuBlock {
    min-width: 400px;
    width: 100vw;
    height: 100%;
    max-height: 80vh;
    max-width: 320px;
    text-align: center;
    background: #008600;
    backdrop-filter: blur(8px);
    transition: ease-out;
    transition-duration: .3s;
    color: white;
    font-family: corbenReg;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    position: absolute;
    top: 0;

}

.menuBlockMy {
    min-height: 600px;
    max-width: 100vw;
    text-align: center;
    background: #008600;
    backdrop-filter: blur(8px);
    transition: ease-out;
    transition-duration: .3s;
    color: white;
    font-family: corbenReg;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    position: absolute;
    top: 0;

}

.order {
    min-width: 400px;
    width: 100vw;
    height: 100%;
    max-height: 80vh;
    max-width: 320px;
    text-align: center;
    background: #2B3618;
    backdrop-filter: blur(8px);
    transition: ease-out;
    transition-duration: .3s;
    color: white;
    font-family: corbenReg;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    position: absolute;
    top: 0;
    right: 0;

}

.orderM {

    width: 100vw;
    height: 100%;
    max-height: 80vh;
    max-width: 320px;
    text-align: center;
    background: #2B3618;
    backdrop-filter: blur(8px);
    transition: ease-out;
    transition-duration: .3s;
    color: white;
    font-family: corbenReg;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    position: absolute;
    top: 0;

}

.orderTitle {
    font-family: corbenBold;
    font-size: 33px;

}

.subTitle {
    font-size: 30px;
    font-weight: 600;
}

.menuTitle {
    font-family: corbenBold;
    font-size: 33px;
    margin-top: 6px;

}

.menuSpecial {
    font-size: 23px;
    width: 80%;
    margin-top: 12px;
}

.menuBlockRow {
    max-width: 80%;
    display: flex;
}

.menuBlockRowM {
    max-width: 100vw;

}

.rightImgCont {
    width: 90vw;
    max-width: 1500px;
    height: 100%;
    max-height: 700px;
    display: flex;
    right: 0;
    margin-left: auto;

}

.rightImgContM {
    width: 100vw;
    height: 90px;
    margin-top: 300px;

}

.sect1 {
    width: calc(50vw -400px);
    overflow-y: hidden;
    height: 100%;
    max-height: 650px;
}

.sect2 {
    width: calc(50% -400px);
    overflow-y: clip;
    height: 100%;
    max-height: 650px;
}

.menulist {
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 40%;

}

.menuBlockRow {

    width: 70%;
    display: flex;
    justify-content: space-between;

}

.reviewCont {
    top: 0;

    max-width: 100vw !important;

}

.reviewBox {
    background-color: #606060;
    height: 350px;

    display: flex;
    flex-direction: column;
    color: white;
    justify-content: center;
    align-content: center;
    justify-items: center;
    align-items: center;
}

.reviewRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 97%;
    max-width: 900px;
    justify-self: center;

}


.customerBox {
    background-color: #008600;
    border-radius: 3px;

    margin-top: 9px;
    min-height: 200px;
    height: fit-content;
    width: 600px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-family: corbenReg;


}

.arrowCont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60px;
}

.customerReview {
    width: 85%;
}

.customerName {
    margin-top: 9px;
}

.arrowRow {
    margin-top: auto;
    display: flex;
}

.gBanner {
    text-align: center;
    background-color: #008600;
    color: white;
    font-family: corbenReg;
    font-weight: 600;
    height: 90px;

    z-index: 1;
    width: 100vw;


}

.address {
    font-size: 23px;
}

@media only screen and (max-width: 1000px) {
    .address {
        font-size: 14px;
    }
}

@media only screen and (max-width: 1000px) {
    .gBanner {
        height: 60px;
    }
}

.gBanner2 {
    text-align: center;
    background-color: #008600;
    color: white;
    font-family: corbenReg;
    font-weight: 600;
    height: 50px;

    z-index: 1;
    width: 100vw;
    max-width: 100vw;

}

.row {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    font-size: 14px;

}

.padding {
    padding: 3px;
}