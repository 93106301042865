@import url('https://fonts.cdnfonts.com/css/work-sans');
html, body{
  min-height: min-content;
  height: 100%;
  width: 100vw;
  max-width: 100vw !important;
  overflow-x: hidden;
}
.body::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
*::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: corbenReg;
  src: url(./Corben/Corben-Regular.ttf);
}
@font-face {
  font-family: corbenBold;
  src: url(./Corben/Corben-Bold.ttf);
}
