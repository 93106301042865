.main {
    top: 0;
    margin-top: 0;
    min-height: 100vh;
    width: 100vw;
    max-width: 100vw;
    max-width: 100vw !important;
    display: inline;

}

.container {
    min-height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.titleBanner {
    width: 100%;
    height: 100px;
    margin-top: 123px;
    margin-bottom: 9px;
    color: white;
    background-color: #008600;
    text-align: center;
    font-family: corbenReg;
    font-size: 48px;
    font-weight: 900
}
.italic{
    font-style: italic;
    font-size: 12px;
}

.menuSubTitle{
    font-family: corbenReg;
    font-size: 30px;
    font-weight: 900;
    text-align: center;
}
.collumnRow{
    display: flex;
    justify-content: center;
    width: 900px;
    max-width: 97vw;
    flex-wrap: wrap;
    margin-bottom: 33px;
}
.collumn{
    display: flex;
    flex-direction: column;
}

.itemRow{
    display: flex;
    justify-content: space-between;
    width: 380px;
    max-width: 97vw;
   
}
.itemCollumn{
    display: flex;
    flex-direction: column;
}
.itemTitle{
    font-family: corbenReg;
    font-size: 20px;
}
.itemDesc{
    font-weight: 300;
    font-size: 14px;
    max-width: 70%;
    font-family: corbenReg;
    line-height: 18px;
    font-weight: 100;
}
.itemPrice{
    font-family: corbenReg;
    font-size: 20px;
    max-width: 70%;
    padding-right: 12px;
}