.main {
    top: 0;
    margin-top: 0;
    min-height: 100vh;
    width: 100vw;
    max-width: 100vw;
    display: inline;
    font-family: corbenReg;

}

.container {
    min-height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.titleBanner {
    width: 100%;
    height: 100px;
    margin-top: 123px;
    color: white;
    background-color: #008600;
    text-align: center;
    font-family: corbenReg;
    font-size: 48px;
    font-weight: 900
}
.strip{
    margin-top: 33px;
    width: 100%;
    max-width: 1000px;
    
    display: flex;
    flex-wrap: wrap;
}
.aboutSect{
    width: 50%;
    max-width: 500px;
    margin-left: 33px;
    
}
@media(max-width: 656px){
    .aboutSect{
        width: 90%;
        margin-bottom: 33px;
    }
}
.aboutSect2{
    width: 80%;
   
    margin-left: 33px;
    
    align-self: center;
    justify-self: center;
    margin-bottom: 33px;
}
@media(max-width: 656px){
    .aboutSect{
        width: 90%;
        margin-bottom: 33px;
    }
    
}
.aboutTitle{
    font-size: 333px;
    font-weight: 800;
    font-family: corbenReg;
    
}
.aboutTitle2{
    font-size: 33px;
    font-family: corbenReg;
}
.aboutTxt{
    font-family: corbenReg;
}
.rightImgCont{
    max-width: 500px;
    margin-left: auto;
}
.rehan{
    max-width: 300px;
    width: 880%;
}
@media(max-width: 662px){
    .rightImgCont{
       margin-left: 33px;
        
        margin-bottom: 33px;
        margin-right: 25%;
    }
    
}
.leftImgCont{
   
    margin-left: 33px;
    max-width: 88%;
}
.mairaTeam{

    max-width: 600px;
    width: 100%;
    
}
.connectBanner{
    width: 100%;
    display: flex;
    height: 100px;
    justify-content: center;
    align-items: center;
}
.connectTxt{
    font-size: 28px;
    font-family: corbenReg;
}
@media(max-width: 380px){
    .connectTxt{
       font-size: 18px;
    }
    
}
.fb{
    cursor: pointer;
    margin: 12px;
}
.yelp{
    height: 40px;
    cursor: pointer;
}
