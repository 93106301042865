.main {
    top: 0;
    margin-top: 0;
    min-height: 100vh;
    width: 100vw;
    max-width: 100vw;
    display: inline;

}

.container {
    min-height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.titleBanner {
    width: 100%;
    height: 100px;
    margin-top: 123px;
    color: white;
    background-color: #2B3618;
    text-align: center;
    font-family: corbenReg;
    font-size: 48px;
    font-weight: 900
}

.row {
    display: flex;
    height: 90px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 12px;
}
.txt{
    font-weight: 300;
    font-size: 18px;
}
.boldTxt{
    font-weight: 900;
    font-size: 18px;
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 12px;
    font-family: corbenBold;
}
.smallTxt{
    font-weight: 300;
    margin-left: 9px;
    margin-right: 9px;
   min-height: fit-content;
    padding-bottom: 12px;
    margin-top: 12px;
}
.dTxt{
    font-family: corbenReg;
    font-size: 22px;
    line-height: 24px;
    margin-top: 9px;
}