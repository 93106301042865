.main{
    text-align: center;
    background-color: #008600;
    color: white;
    font-family: corbenReg;
    font-weight: 600;
    height: 30px;
  position: fixed;
    z-index: 11 !important;
    width: 100vw;

    
}